@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}


code {
  font-family: "Raleway", sans-serif;
}

body::-webkit-scrollbar {
  display: none;
}

h1,
span {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 40px;
}


p,
Link,
.btn {
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  font-family: "Raleway", sans-serif;
}

input[type="text"]::placeholder,
textarea[type="text"]::placeholder,
input[type="email"]::placeholder {
  color: black;
  /* Placeholder color */
}

textarea::-webkit-scrollbar {
  display: none;
}